import React from 'react'

// also known as pen-01
export default function Pen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
    >
      <title>pen 01</title>
      <g fill="#f9fafc">
        <path
          fill="#f9fafc"
          d="M8.1,3.5l-7.8,7.8C0.1,11.5,0,11.7,0,12v3c0,0.6,0.4,1,1,1h3c0.3,0,0.5-0.1,0.7-0.3l7.8-7.8L8.1,3.5z"
        />{' '}
        <path d="M15.7,3.3l-3-3c-0.4-0.4-1-0.4-1.4,0L9.5,2.1l4.4,4.4l1.8-1.8C16.1,4.3,16.1,3.7,15.7,3.3z" />
      </g>
    </svg>
  )
}
