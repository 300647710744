// libraries
import React from "react";

export default function Discord() {
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#E6E7EC" fillRule="nonzero">
        <path d="M11.104 13.424a1.782 1.782 0 0 0 0 3.552 1.704 1.704 0 0 0 1.632-1.776 1.694 1.694 0 0 0-1.632-1.776zm5.84 0a1.782 1.782 0 1 0 1.632 1.776 1.704 1.704 0 0 0-1.632-1.776z" />
        <path d="M24.72 0H3.28A3.288 3.288 0 0 0 0 3.296v21.632a3.288 3.288 0 0 0 3.28 3.296h18.144l-.848-2.96 2.048 1.904 1.936 1.8L28 32V3.296A3.288 3.288 0 0 0 24.72 0zm-6.176 20.896s-.576-.696-1.056-1.296a5.048 5.048 0 0 0 2.896-1.904c-.576.383-1.193.7-1.84.944a10.53 10.53 0 0 1-2.32.688 11.2 11.2 0 0 1-4.144-.016 13.432 13.432 0 0 1-2.352-.688 9.366 9.366 0 0 1-1.824-.944 4.976 4.976 0 0 0 2.8 1.888c-.48.6-1.072 1.328-1.072 1.328a5.8 5.8 0 0 1-4.88-2.432c.052-3.244.84-6.433 2.304-9.328a7.912 7.912 0 0 1 4.496-1.68l.16.2a10.8 10.8 0 0 0-4.2 2.096s.352-.2.944-.464c1.15-.522 2.376-.86 3.632-1 .09-.019.18-.03.272-.032a13.538 13.538 0 0 1 3.224-.056c1.69.193 3.326.715 4.816 1.536a10.648 10.648 0 0 0-3.984-2.024l.224-.256a7.912 7.912 0 0 1 4.496 1.68 21.426 21.426 0 0 1 2.304 9.328 5.848 5.848 0 0 1-4.896 2.432z" />
      </g>
    </svg>
  );
}
