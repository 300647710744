import React from 'react';

// also known as user-hollow
export default function Offline() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>offline</title>
      <g>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-264 -743)" fill="#F9FAFC" fillRule="nonzero">
            <g transform="translate(264 743)">
              <path d="M7 0c2.2 0 4 1.8 4 4v1a4.013 4.013 0 0 1-3.801 3.995L7 9C4.8 9 3 7.2 3 5V4A4.013 4.013 0 0 1 6.801.005L7 0zm0 2l-.1.002A2.014 2.014 0 0 0 5.006 3.85L5 4v1c0 1.046.824 1.917 1.809 1.995l.142.006.149-.003A2.014 2.014 0 0 0 8.994 5.15L9 5V4a2.013 2.013 0 0 0-1.852-1.994L7 2z" />
              <path d="M9 10c2.733 0 4.895 2.516 4.996 5.761L14 16H0l.004-.239c.099-3.164 2.156-5.635 4.792-5.756L5 10h4zm0 2H5c-1.032 0-1.979.69-2.522 1.798L2.385 14h9.224l-.03-.072c-.505-1.126-1.411-1.853-2.42-1.922L9 12z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
