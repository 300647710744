// libraries
import React from 'react';

export default function Ex() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="3"
      viewBox="0 0 12 3"
    >
      <g fill="#f9fafc" transform="translate(0 -4.5)">
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(4.5 4.5)" />
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(9 4.5)" />
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0 4.5)" />
      </g>
    </svg>
  );
}
