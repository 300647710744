// libraries
import React from 'react';

export default function Medal() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="nonzero">
        <circle fill="currentColor" cx="16" cy="24" r="8" />
        <path
          fill="currentColor"
          d="M28 0H4L0 6l7.2 10c1.6-1.8 3.8-3 6.2-3.6L10 8h12l-3.2 4.4c2.4.6 4.6 1.8 6.2 3.6l7-10-4-6z"
        />
      </g>
    </svg>
  );
}
