// libraries
import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import * as Sentry from '@sentry/browser';

import * as config from 'config';

import opengraph from 'images/opengraph.png';

if (config.shouldReportErrors) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV,
    release: config.SENTRY_RELEASE,
    debug: config.ENV !== 'production'
  });
}

const DEFAULT_TITLE = 'Game, Work, and Play Together From Anywhere';
const DEFAULT_DESCRIPTION =
  'Parsec connects millions of people to their work, games, and friends from anywhere, across any device in silky smooth, ultra high definition interactive video. Try it now.';

interface Props {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  children?: ReactNode;
}

export default function Head(props: Props) {
  const {
    children,
    title = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION,
    ogTitle = `${title || DEFAULT_TITLE} | Parsec`,
    ogDescription = description,
    ogImage = opengraph
  } = props;

  return (
    <Helmet>
      <title>{title} | Parsec</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="multiplayer gaming, remote desktop, remote connection, remote collaboration"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@parsecteam" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:image" content={`https://parsec.app${ogImage}`} />
      <meta property="og:url" content="https://parsec.app" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:site_name" content="Parsec Gaming" />
      <meta property="og:image" content={`https://parsec.app${ogImage}`} />

      {/* Google Tag Manager */}
      {config.shouldLoadAnalytics && (
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${config.GTM_AUTH}&gtm_preview=${config.GTM_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PTKRK7');`}</script>
      )}
      {children}
    </Helmet>
  );
}
