// libraries
import React from 'react';

export default function Star() {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.624 11.387l-9.062-1.371-4.055-8.555a1.325 1.325 0 0 0-1.152-.689c-.474 0-.913.263-1.152.69l-4.045 8.554-9.062 1.37a1.296 1.296 0 0 0-1.034.908 1.372 1.372 0 0 0 .325 1.367l6.558 6.667-1.551 9.403a1.36 1.36 0 0 0 .51 1.305c.394.298.917.337 1.348.1l8.108-4.448 8.108 4.44c.182.101.386.153.593.152.378 0 .737-.172.98-.472.244-.3.349-.698.285-1.085l-1.551-9.403 6.558-6.667c.35-.354.475-.884.325-1.367a1.296 1.296 0 0 0-1.034-.907v.008z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
