export const ENV = process.env.ENV || '';
export const DOMAIN = process.env.DOMAIN || '';
export const TEAMS_URL = process.env.TEAMS_URL || '';
export const APP_URL = process.env.APP_URL || '';
export const KESSEL_URL = process.env.KESSEL_URL || '';
export const STRIPE_KEY = process.env.STRIPE_KEY || '';
export const SENTRY_DSN = process.env.SENTRY_DSN || '';
export const SENTRY_RELEASE = process.env.GATSBY_SENTRY_RELEASE || '';
export const GTM_AUTH = process.env.GTM_AUTH || '';
export const GTM_PREVIEW = process.env.GTM_PREVIEW || '';

export const shouldLoadStripe = ENV !== 'testing';
export const shouldLoadAnalytics =
  ENV === 'production' || ENV === 'staging' || ENV === 'falcon';
export const shouldReportErrors =
  ENV === 'production' || ENV === 'staging' || ENV === 'falcon';

export const DOWNLOADS = {
  windows: 'https://builds.parsecgaming.com/package/parsec-windows.exe',
  windows32: 'https://builds.parsecgaming.com/package/parsec-windows32.exe',
  windowsFlat:
    'https://builds.parsecgaming.com/package/parsec-flat-windows32.zip',
  macos: 'https://builds.parsecgaming.com/package/parsec-macos.pkg',
  android: 'https://play.google.com/store/apps/details?id=tv.parsec.client',
  rpi: 'https://builds.parsecgaming.com/package/parsec-rpi.deb',
  linux: 'https://builds.parsecgaming.com/package/parsec-linux.deb'
};
