// libraries
import React from 'react';

export default function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="#E6E7EC"
        d="M16 3.7c4 0 4.479.015 6.061.087a6.426 6.426 0 014.51 1.639 6.426 6.426 0 011.639 4.51c.072 1.585.09 2.064.09 6.064s-.015 4.479-.087 6.061a6.426 6.426 0 01-1.639 4.51 6.425 6.425 0 01-4.51 1.639c-1.582.072-2.056.087-6.061.087s-4.479-.015-6.061-.087a6.426 6.426 0 01-4.51-1.639 6.425 6.425 0 01-1.639-4.51C3.718 20.479 3.7 20.005 3.7 16s.015-4.479.087-6.061a6.426 6.426 0 011.639-4.51A6.426 6.426 0 019.939 3.79C11.521 3.718 12 3.7 16 3.7M16 1c-4.073 0-4.584.017-6.185.09a8.974 8.974 0 00-6.3 2.427 8.971 8.971 0 00-2.427 6.3C1.017 11.416 1 11.927 1 16s.017 4.584.09 6.185a8.974 8.974 0 002.427 6.3 8.971 8.971 0 006.3 2.427c1.6.073 2.112.09 6.185.09s4.584-.017 6.185-.09a8.974 8.974 0 006.3-2.427 8.971 8.971 0 002.427-6.3c.073-1.6.09-2.112.09-6.185s-.017-4.584-.09-6.185a8.974 8.974 0 00-2.427-6.3 8.971 8.971 0 00-6.3-2.427C20.584 1.017 20.073 1 16 1z"
      />
      <path
        fill="#E6E7EC"
        d="M16 8.3a7.7 7.7 0 107.7 7.7A7.7 7.7 0 0016 8.3zM16 21a5 5 0 115-5 5 5 0 01-5 5z"
      />
      <circle fill="#E6E7EC" cx="24.007" cy="7.993" r="1.8" />
    </svg>
  );
}
