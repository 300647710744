// libraries
import React from 'react';

export default function Ban() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <title>bulb-61</title>
      <g fill="#e6e7ec">
        <path
          fill="#e6e7ec"
          d="M14,6c0-3.3-2.7-6-6-6S2,2.7,2,6c0,2.2,1.2,4.2,3,5.2V13h6v-1.8C12.8,10.2,14,8.2,14,6z"
        ></path>
        <path
          data-color="color-2"
          d="M7,16h2c1.1,0,2-0.9,2-2H5C5,15.1,5.9,16,7,16z"
        ></path>
      </g>
    </svg>
  );
}
