// libraries
import React from "react";

export default function Facebook() {
  return (
    <svg
      width="20"
      height="32"
      viewBox="0 0 20 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.046 32L6 18H0v-6h6V8c0-5.398 3.343-8 8.159-8 2.307 0 4.29.172 4.867.248V5.89l-3.34.002c-2.62 0-3.126 1.244-3.126 3.07V12H20l-2 6h-5.44v14H6.046z"
        fill="#E6E7EC"
        fillRule="nonzero"
      />
    </svg>
  );
}
