// libraries
import React from 'react';

export default function Privacy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="#f9fafc">
        <path d="M9.25 5.5a1.25 1.25 0 00-2.5 0V7h2.5z" />
        <path d="M14.165 1.014l-6-1a1.015 1.015 0 00-.33 0l-6 1A1 1 0 001 2v7a7 7 0 0014 0V2a1 1 0 00-.835-.986zM12 11a1 1 0 01-1 1H5a1 1 0 01-1-1V8a1 1 0 011-1h.25V5.5a2.75 2.75 0 015.5 0V7H11a1 1 0 011 1z" />
      </g>
    </svg>
  );
}
