// libraries
import React from 'react';

export default function Website() {
  return (
    <svg
      width="37"
      height="32"
      viewBox="0 0 37 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.286 0h-32A2.286 2.286 0 0 0 0 2.286v27.428A2.286 2.286 0 0 0 2.286 32h32a2.286 2.286 0 0 0 2.285-2.286V2.286A2.286 2.286 0 0 0 34.286 0zM4.57 4.571h18.286V27.43H4.571V4.57z"
        fill="#E6E7EC"
        fillRule="nonzero"
      />
    </svg>
  );
}
