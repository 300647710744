// libraries
import React from 'react';

export default function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="#000000" d="M9 21l-8-8 3-3 5 5L21 3l3 3L9 21z" />
    </svg>
  );
}
