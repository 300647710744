import React from 'react';

// libraries
import { css } from '@emotion/core';

import { default as arrow } from 'images/icons/arrow';
import { default as arrowLeft } from 'images/icons/arrowLeft';
import { default as arrowRight } from 'images/icons/arrowRight';
import { default as ban } from 'images/icons/ban';
import { default as bulb } from 'images/icons/bulb';
import { default as check } from 'images/icons/check';
import { default as circle } from 'images/icons/circle';
import { default as collapse } from 'images/icons/collapse';
import { default as controller } from 'images/icons/controller';
import { default as discord } from 'images/icons/discord';
import { default as github } from 'images/icons/github';
import { default as group } from 'images/icons/group';
import { default as earth } from 'images/icons/earth';
import { default as ellipsis } from 'images/icons/ellipsis';
import { default as ex } from 'images/icons/ex';
import { default as expand } from 'images/icons/expand';
import { default as gavel } from 'images/icons/gavel';
import { default as facebook } from 'images/icons/facebook';
import { default as help } from 'images/icons/help';
import { default as info } from 'images/icons/info';
import { default as instagram } from 'images/icons/instagram';
import { default as lock } from 'images/icons/lock';
import { default as medal } from 'images/icons/medal';
import { default as menu } from 'images/icons/menu';
import { default as privacy } from 'images/icons/privacy';
import { default as receipt } from 'images/icons/receipt';
import { default as resize } from 'images/icons/resize';
import { default as send } from 'images/icons/send';
import { default as letter } from 'images/icons/letter';
import { default as shield } from 'images/icons/shield';
import { default as star } from 'images/icons/star';
import { default as twitter } from 'images/icons/twitter';
import { default as warp } from 'images/icons/warp';
import { default as website } from 'images/icons/website';
import { default as youtube } from 'images/icons/youtube';
import { default as offline } from 'images/icons/offline';
import { default as pen } from 'images/icons/pen';

const icons = {
  arrow,
  arrowLeft,
  arrowRight,
  ban,
  bulb,
  check,
  circle,
  collapse,
  controller,
  discord,
  earth,
  ellipsis,
  ex,
  expand,
  facebook,
  gavel,
  github,
  group,
  help,
  info,
  instagram,
  lock,
  medal,
  menu,
  offline,
  pen,
  privacy,
  receipt,
  resize,
  send,
  letter,
  shield,
  star,
  twitter,
  warp,
  website,
  youtube
};

export type IconNames = keyof typeof icons;

interface Props {
  className?: string;
  name: IconNames;
}

export default function Icon(props: Props) {
  const { className, name } = props;
  const SVG = icons[name];

  return (
    <span className={className} css={style()}>
      <SVG />
    </span>
  );
}

const style = (color = 'currentColor') => css`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;

  > svg {
    display: block;
    width: 100%;
    height: 100%;

    [fill^='#'] {
      fill: ${color};
    }

    [stroke^='#'] {
      stroke: ${color};
    }
  }
`;
