// libraries
import React from 'react';

export default function Twitter() {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 3c-1.2.6-2.4.8-3.8 1 1.4-.8 2.4-2 2.8-3.6-1.2.8-2.6 1.2-4.2 1.6-1.2-1.2-3-2-4.8-2-3.4 0-6.4 3-6.4 6.6 0 .6 0 1 .2 1.4-5.4-.2-10.4-2.8-13.6-6.8-.6 1-.8 2-.8 3.4 0 2.2 1.2 4.2 3 5.4-1 0-2-.4-3-.8 0 3.2 2.2 5.8 5.2 6.4-.6.2-1.2.2-1.8.2-.4 0-.8 0-1.2-.2.8 2.6 3.2 4.6 6.2 4.6C7.6 22 4.8 23 1.6 23H0c3 1.8 6.4 3 10 3 12 0 18.6-10 18.6-18.6v-.8C30 5.6 31.2 4.4 32 3z"
        fill="#E6E7EC"
        fillRule="nonzero"
      />
    </svg>
  );
}
