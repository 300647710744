import React from 'react'

// also known as users-wm
export default function Group() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
    >
      <g fill="#f9fafc">
        <path d="M14.864,10.216l-2.557-.639a.5.5,0,0,1-.369-.388l-.191-.963A3,3,0,0,0,13.5,5.5V4.126A3.084,3.084,0,0,0,10.592,1,3,3,0,0,0,7.97,2.393,4.207,4.207,0,0,1,9,5.128a4.239,4.239,0,0,0,.584,2.457,1.5,1.5,0,0,1-.534,2.1l.137.04A2.51,2.51,0,0,1,11,12.131V14.5a1.483,1.483,0,0,1-.092.5H15.5a.5.5,0,0,0,.5-.5V11.671A1.5,1.5,0,0,0,14.864,10.216Z" />
        <path
          d="M8.912,10.689l-2.157-.616a.5.5,0,0,1-.338-.325l-.17-.522a4.949,4.949,0,0,0,2.3-.4.5.5,0,0,0,.192-.712A5.233,5.233,0,0,1,8,5.131,3.093,3.093,0,0,0,5.119,2,3,3,0,0,0,2,5a5.374,5.374,0,0,1-.736,3.115.5.5,0,0,0,.193.711,4.949,4.949,0,0,0,2.3.4l-.17.522a.5.5,0,0,1-.338.325l-2.157.616A1.5,1.5,0,0,0,0,12.131V14.5a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5V12.131A1.5,1.5,0,0,0,8.912,10.689Z"
          fill="#f9fafc"
        />
      </g>
    </svg>
  )
}
