// libraries
import React from "react";

export default function YouTube() {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6 5.6C31.2 3 30 1.2 27.2.8 22.8 0 16 0 16 0S9.2 0 4.8.8C2 1.2.6 3 .4 5.6 0 8.2 0 12 0 12s0 3.8.4 6.4C.8 21 2 22.8 4.8 23.2 9.2 24 16 24 16 24s6.8 0 11.2-.8c2.8-.6 4-2.2 4.4-4.8.4-2.6.4-6.4.4-6.4s0-3.8-.4-6.4zM12 18V6l10 6-10 6z"
        fill="#E6E7EC"
        fillRule="nonzero"
      />
    </svg>
  );
}
