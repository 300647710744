// libraries
import React from 'react';

export default function Arrow() {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7.4L.6 2 2 .6l4 4 4-4L11.4 2z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
