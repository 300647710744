// libraries
import React from 'react';

export default function Lock() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5H8V3a3 3 0 00-6 0v2H1a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1zM6 5H4V3a1 1 0 112 0v2z"
        fill="#F9FAFC"
        fillRule="nonzero"
      />
    </svg>
  );
}
