// libraries
import React from 'react';

export default function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#f40048"
        d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,14a6,6,0,1,1,6-6A6.018,6.018,0,0,1,8,14Z"
      />
      <rect fill="#f40048" width="2" height="5" transform="translate(7 7)" />
      <circle fill="#f40048" cx="1" cy="1" r="1" transform="translate(7 4)" />
    </svg>
  );
}
