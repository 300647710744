import React from 'react';

export default function Circle() {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Z"
        fill="#000000"
      />
    </svg>
  );
}
