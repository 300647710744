// libraries
import React from 'react';

export default function Lock() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#F9FAFC" fillRule="nonzero">
        <path d="M8 9a.5.5 0 00-.5-.5h-6A.5.5 0 001 9v1.5H0v1h9v-1H8V9zM0 6.5h2.5v1H0zM1.111 3.818l.708-.707 1.767 1.768-.707.707zM9.384 3.823l1.47-1.47a.5.5 0 000-.707l-1.5-1.5a.5.5 0 00-.707 0l-4 4a.5.5 0 000 .707l1.5 1.5a.499.499 0 00.707 0l1.469-1.469 2.146 2.146 1.061-1.06-2.146-2.147z" />
      </g>
    </svg>
  );
}
