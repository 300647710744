// libraries
import React from 'react';

export default function Warp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.09 135.1">
      <path
        d="M131.59,48.36a50.24,50.24,0,0,0-85.75-6.22,45.88,45.88,0,0,1,5.81-10.82,46.68,46.68,0,0,1,59-13.9L122,23.32l-9.11-8.93a49.16,49.16,0,0,0-5.69-4.82A50.21,50.21,0,0,0,34,75a44.93,44.93,0,0,1-2.86-3.53,46.67,46.67,0,0,1,5-60.41L45.24,2.1,33.91,8A50.22,50.22,0,0,0,16.47,82.09a49.85,49.85,0,0,0,32.77,20.08,51.25,51.25,0,0,0,8,.63,49.84,49.84,0,0,0,7.73-.6c-1.1.45-2.2.89-3.35,1.26A46.68,46.68,0,0,1,5.69,80L0,68.59,2.12,81.18a51.68,51.68,0,0,0,1.77,7.24,50.26,50.26,0,0,0,97.89-11.78,45.72,45.72,0,0,1,.75,8.23A46.68,46.68,0,0,1,63,130.8l-12.61,1.87L63,134.54A50.22,50.22,0,0,0,91.39,39.25a46.11,46.11,0,0,1,36.84,53.18L126.11,105l5.68-11.41a50.27,50.27,0,0,0-.2-45.23ZM89,65.43A20.75,20.75,0,1,1,68.28,44.69,20.78,20.78,0,0,1,89,65.43Z"
        fill="#F9FAFC"
      />
    </svg>
  );
}
