// libraries
import React from 'react';

export default function Earth() {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#E6E7EC" fillRule="nonzero">
        <path d="M20 13.2V16c0 .8.533 1.333 1.333 1.333.8 0 1.334-.533 1.334-1.333v-2.8C25.733 12.533 28 9.867 28 6.667 28 2.933 25.067 0 21.333 0c-3.733 0-6.666 2.933-6.666 6.667 0 3.2 2.266 5.866 5.333 6.533z" />
        <path d="M28.533 12.533c-.666.8-1.333 1.467-2.266 2 .266.934.4 1.867.4 2.8 0 1.867-.4 3.734-1.2 5.334-2.667 1.466-3.867.666-5.067-.267-.8-.667-2-1.467-3.467-.933-1.466.533-2.533 1.733-3.2 3.2-.533 1.333-.666 3.066-.4 4.666a11.236 11.236 0 0 1-6.8-3.2c.934-2 .934-4-.266-5.733C6 19.867 5.6 19.467 5.2 19.067c-.667-.667-.4-1.867.4-2.267 1.067-.533 2.267-1.067 3.067-2.133 1.6-2.4-.267-5.867-.667-6.534-.133-.133-.267-.266-.4-.533a13.18 13.18 0 0 1 4.4-2c.133-1.067.4-2 .8-2.8C5.6 3.733 0 9.867 0 17.333A14.624 14.624 0 0 0 14.667 32a14.624 14.624 0 0 0 14.666-14.667c0-1.6-.266-3.2-.8-4.8z" />
      </g>
    </svg>
  );
}
