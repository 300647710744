// libraries
import React from 'react';

export default function Resize() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path d="M4.844 14.405L.219 8.625a1.001 1.001 0 010-1.25l4.625-5.78 1.562 1.249L2.28 8l4.125 5.156-1.561 1.249zM11.156 14.405l-1.562-1.249L13.72 8 9.595 2.844l1.562-1.249 4.625 5.78c.292.365.292.885 0 1.25l-4.626 5.78z" />
    </svg>
  );
}
