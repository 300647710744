// libraries
import React from 'react';

export default function Ban() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 3a8.942 8.942 0 015.206 1.672L4.672 17.206A8.986 8.986 0 0112 3zm0 18a8.942 8.942 0 01-5.206-1.672L19.328 6.794A8.986 8.986 0 0112 21z" />
    </svg>
  );
}
