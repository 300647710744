// libraries
import React from 'react';

export default function Receipt() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        d="M14 0H2c-.6 0-1 .4-1 1v15l3-2 2 2 2-2 2 2 2-2 3 2V1c0-.6-.4-1-1-1zm-2 10H4V8h8v2zm0-4H4V4h8v2z"
        fill="#f9fafc"
      />
    </svg>
  );
}
