// libraries
import React from 'react';

export default function YouTube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <title>menu-6</title>
      <g fill="#000000">
        <rect fill="#000000" width="16" height="3"></rect>{' '}
        <rect data-color="color-2" y="6" width="16" height="3"></rect>{' '}
        <rect y="12" fill="#000000" width="16" height="3"></rect>
      </g>
    </svg>
  );
}
