// libraries
import React from 'react';

export default function Controller() {
  return (
    <svg
      width="33"
      height="23"
      viewBox="0 0 33 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 18L30.4 5c-.6-2.8-3.2-5-6.2-5-3.6 0-5.8 3.4-6.2 4h-2c-.4-.6-2.6-4-6.2-4-3 0-5.6 2.2-6.2 5L1 18c-.2 1.4.2 2.8 1.4 3.4 1.2.8 2.6.6 3.8-.2l7.4-5.2h6.6l7.4 5.4c1.2.8 2.4 1 3.8.2 1.2-.8 1.8-2.2 1.6-3.6zm-20-8h-2v2H9v-2H7V8h2V6h2v2h2v2zm10 2h-2v-2h2v2zm0-6h2v2h-2V6zm4 6h-2v-2h2v2z"
        fill="#E6E7EC"
        fillRule="nonzero"
      />
    </svg>
  );
}
