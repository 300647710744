// libraries
import React from 'react';

export default function Collapse() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.98 5.38 18.62.02 12 0zm-1 13H6v-2h12v2h-7z"
        fill="currentColor"
      />
    </svg>
  );
}
